import "./bootstrap";
import Vue from "vue";
import axios from "axios";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import { isLoggedIn, validateSession } from "./config/auth";
import VueSweetalert2 from "vue-sweetalert2";

import "@mdi/font/css/materialdesignicons.css";
import "sweetalert2/dist/sweetalert2.min.css";

import "vuetify/dist/vuetify.min.css";

import es from "vuetify/es5/locale/es";

import ECharts from "vue-echarts";
import { use } from "echarts/core";

import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent, TooltipComponent } from "echarts/components";


use([CanvasRenderer, BarChart, GridComponent, TooltipComponent]);

Vue.component("v-chart", ECharts);
Vue.use(Vuetify);
Vue.use(VueSweetalert2);
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;


router.beforeEach(async (to, from, next) => {
  if (!to.matched.some((record) => record.meta.isPublic) && !isLoggedIn()) {
    next({
      path: "/auth/login",
      query: { redirect: to.fullPath },
    });
  } else {
    await validateSession();

    const nearestWithTitle = to.matched
      .slice()
      .reverse()
      .find((record) => record.meta && record.meta.title);

    if (nearestWithTitle) {
      document.title = to.meta.title;
    }
    next();
  }
});

new Vue({
  router,
  store,
  vuetify: new Vuetify({
    icons: {
      iconfont: "mdi",
    },
    lang: {
      locales: { es },
      current: "es",
    },
  }),
  render: (h) => h(App),
}).$mount("#app");
