import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "*", component: () => import("@/views/others/404.vue") },
  {
    path: "/",
    component: () => import("@/views/auth/Layout.vue"),
    meta: { isPublic: true },
    redirect: { name: "login" },
    children: [
      {
        path: "login",
        name: "login",
        meta: { title: "Login" },
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "/home",
        name: "home",
        meta: { title: "Home" },
        component: () => import("@/views/home/Home.vue")
      },
      {
        path: "/linguo",
        name: "spelling",
        meta: { title: "Corrector ortográfico" },
        component: () => import("@/views/auth/Spelling.vue")
      },
      // {
      //   path: "/admin",
      //   name: "admin",
      //   meta: { title: "Administrador" },
      //   component: () => import("@/views/admin/Login.vue")
      // },

    ],
  },
  {
    path: "/admin",
    component: () => import("@/views/admin/Layout.vue"),
    redirect: { name: "loginAdmin" },
    children: [
      {
        path: "login",
        name: "loginAdmin",
        meta: { title: "LoginAdmin" },
        component: () => import("@/views/admin/Login.vue"),
      },
      // {
      //   path: "home",
      //   name: "homeAdmin",
      //   meta: { title: "HomeAdmin" },
      //   component: () => import("@/views/admin/Home.vue"),
      // },
    ],
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
