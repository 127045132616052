import axios from "axios";

const ID_TOKEN_KEY = "token";

export function isLoggedIn() {
  const idToken = getIdToken();
  setAxiosHeader();
  return !!idToken;
}

export function getIdToken() {
  if (process.env.NODE_ENV === "test") {
    return process.env.TEST_TOKEN;
  } else {
    return localStorage.getItem(ID_TOKEN_KEY);
  }
}

export function noAuth(to, from, next) {
  if (isLoggedIn()) {
    next({
      path: "/",
    });
  } else {
    next();
  }
}

export function logout() {
  clearIdToken();
  //localStorage.removeItem('rol');
  //localStorage.removeItem('userId');
}

export function login(idToken) {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
  setAxiosHeader();
}

function clearIdToken() {
  localStorage.removeItem(ID_TOKEN_KEY);
}

export function setAxiosHeader() {
  axios.defaults.headers.common["Authorization"] = "Bearer " + getIdToken();
}

export function loginGoogle(idToken) {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
  setAxiosHeader();
}

export async function validateSession() {
  let token = await localStorage.getItem("pruebaToken");
  let isRedirect = location.search.split("?")[1];
  if (token) {
    axios
      .get(`${process.env.VUE_APP_API_URL}/api/auth/currentUser`, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(() => {
        if (!isRedirect) {
          window.location.href = process.env.VUE_APP_REDIRECT_OKAN + token;
        } else {
          localStorage.clear();
          sessionStorage.clear();
        }
      })
      .catch(() => {
        localStorage.clear();
        sessionStorage.clear();
      });
  }
}
